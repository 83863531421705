<template>
  <Section :options="computedOptions.sectionOptions">
      <LocalizedContent :localizedContent="labels.content"></LocalizedContent>
  </Section>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component, Prop } from '@fwk-node-modules/vue-property-decorator';
import Section, {SectionOptions} from '@root/src/client/components/panels/layouts/canvas/Section.vue';
import LocalizedContent from '@fwk-client/components/panels/LocalizedContent.vue';
import { CmsContent } from '@fwk-client/modules/cms/types';

/** @cmsOptions */
export interface StaticContentOptions {
  /** @cmsType CmsInterface */
  sectionOptions?:SectionOptions
}

/** @cmsLabels */
export interface StaticContentLabels {
  /** @cmsType CmsContent */
  content?:CmsContent;
}

@Component({
  components: {
    LocalizedContent,
    Section
  },
  computed: {}
})
export default class StaticContent extends Vue {

  @Prop({
        type: Object,
        required: false,
    }) readonly options!: StaticContentOptions | undefined

    computedOptions:StaticContentOptions = {
        sectionOptions : {
            section : false
        },
        ...this.options
    };

  @Prop({
        type: Object,
        required: false,
        default: () => { return {} }
    }) readonly labels!: StaticContentLabels

}
</script>